import React, { Component } from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import Pancham from "../images/bcorp/pancham.png"

import {Helmet} from "react-helmet"
import Clutch from "../components/clutch"




export default class Container extends Component {
  constructor(props) {
    super(props);
    this.state={
      url: ''
    }
  }
  componentDidMount() {
    this.setState({
      url: document.location.href
    });
  }
  render() {
    return (
      <Layout>
        <Helmet>
          <title>
            Champions Retreat 2024 -  Looking Forward to Growth!        </title>
          <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
              <link rel="canonical" href={this.state.url} />
              <meta name="description" content="CueForGood is joining Champions Retreat 2024 in Vancouver, the much-awaited event for the BCorp movement! Learn about our involvement and conference attendees." />
             <meta property="og:site_name" content="CueForGood" />
              <meta property="fb:app_id" content="289086684439915" />
              <meta property="og:url" content={this.state.url} />
              <meta property="og:title" content="Champions Retreat 2024 -  Looking Forward to Growth!" />
              <meta property="og:description" content="CueForGood is joining Champions Retreat 2024 in Vancouver, the much-awaited event for the BCorp movement! Learn about our involvement and conference attendees." />
              <meta property="og:image" content="https://www.CueForGood.com/images/cb.jpg" />

              <meta name="twitter:card" content="summary_large_image" />
              <meta name="twitter:description" content="CueForGood is joining Champions Retreat 2024 in Vancouver, the much-awaited event for the BCorp movement! Learn about our involvement and conference attendees." />
              <meta name="twitter:title" content="Champions Retreat 2024 -  Looking Forward to Growth!" />
              <meta name="twitter:site" content="@CueForGood" />
              <meta name="twitter:image" content="https://www.CueForGood.com/images/cb.jpg" />
        </Helmet>
        <article className="static_page bcorp-page">
          <section className="bcorp-top">
            <div className="container">
              <div className="bcorp-top-inn">
                <div className="bcorp-top-left">
                <img
                  src={Pancham}
                  alt="Pancham "
                  className="img-responsive"
                />
                </div>
                <div className="bcorp-top-right">
                <div className="tag">CueForGood at</div>
                  <h1 className="heading_main">Champions Retreat 2024 <span>See You There!</span></h1>
                  <div className="bcrop-banner-text">
                    <span className="quote-ion quote-top"><svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.03938 8.81113e-07L5.03938 0.794574C3.8979 1.38889 3.0807 2.00904 2.58779 2.65504C2.09488 3.30103 1.84842 4.00517 1.84842 4.76744C1.84842 5.21964 1.91328 5.52972 2.04299 5.69767C2.15973 5.87855 2.30242 5.96899 2.47105 5.96899C2.63967 5.96899 2.86667 5.92377 3.15204 5.83333C3.43741 5.72997 3.69684 5.6783 3.93033 5.6783C4.46215 5.6783 4.92264 5.87855 5.31178 6.27907C5.71389 6.66667 5.91495 7.1447 5.91495 7.71318C5.91495 8.33333 5.67498 8.86951 5.19504 9.32171C4.71509 9.76098 4.11841 9.98062 3.40499 9.98062C2.5359 9.98062 1.75113 9.60594 1.05068 8.85659C0.350226 8.10723 -1.256e-06 7.18346 -1.06398e-06 6.08527C-8.38084e-07 4.79328 0.428055 3.59173 1.28417 2.48062C2.15325 1.35659 3.40499 0.529716 5.03938 8.81113e-07ZM12.0661 0.0581416L12.0661 0.794576C10.756 1.54393 9.89336 2.21576 9.47827 2.81008C9.06319 3.40439 8.85565 4.10207 8.85565 4.9031C8.85565 5.26486 8.92699 5.53618 9.06968 5.71706C9.21236 5.89793 9.36153 5.98837 9.51719 5.98837C9.65987 5.98837 9.8739 5.93669 10.1593 5.83333C10.4446 5.72998 10.73 5.6783 11.0154 5.6783C11.5472 5.6783 12.0077 5.87209 12.3968 6.25969C12.7989 6.63437 13 7.09949 13 7.65504C13 8.28812 12.7471 8.83721 12.2412 9.30233C11.7483 9.76744 11.1386 10 10.4122 10C9.5561 10 8.78431 9.63178 8.09682 8.89535C7.40934 8.15892 7.0656 7.2416 7.0656 6.14341C7.0656 4.78682 7.50014 3.55297 8.36922 2.44186C9.2383 1.31783 10.4706 0.523258 12.0661 0.0581416Z" fill="white" fill-opacity="0.4"/>
                    </svg></span>
                  
                      <span className="span-li">We are proud to be an eCommerce Agency dedicated to serving B Corps and sustainable eCommerce brands.</span>
                      <span className="span-li">This is the reason I am at the Champions Retreat; to meet and learn from  other like-minded people who are a part of businesses with a Purpose. </span>
                      <span className="span-li">I am eager to meet you, extend a warm greeting and understand if we can help accentuate your impact.</span>
                    
                      <span className="quote-ion quote-bottom"><svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7.96062 8.81113e-07L7.96062 0.794574C9.1021 1.38889 9.9193 2.00904 10.4122 2.65504C10.9051 3.30103 11.1516 4.00517 11.1516 4.76744C11.1516 5.21964 11.0867 5.52972 10.957 5.69767C10.8403 5.87855 10.6976 5.96899 10.529 5.96899C10.3603 5.96899 10.1333 5.92377 9.84796 5.83333C9.56259 5.72997 9.30316 5.6783 9.06967 5.6783C8.53785 5.6783 8.07736 5.87855 7.68822 6.27907C7.28611 6.66667 7.08505 7.1447 7.08505 7.71318C7.08505 8.33333 7.32502 8.86951 7.80496 9.32171C8.28491 9.76098 8.88159 9.98062 9.59501 9.98062C10.4641 9.98062 11.2489 9.60594 11.9493 8.85659C12.6498 8.10723 13 7.18346 13 6.08527C13 4.79328 12.5719 3.59173 11.7158 2.48062C10.8468 1.35659 9.59501 0.529716 7.96062 8.81113e-07ZM0.933935 0.0581416L0.933935 0.794576C2.24404 1.54393 3.10664 2.21576 3.52173 2.81008C3.93681 3.40439 4.14435 4.10207 4.14435 4.9031C4.14435 5.26486 4.07301 5.53618 3.93032 5.71706C3.78764 5.89793 3.63847 5.98837 3.48281 5.98837C3.34013 5.98837 3.1261 5.93669 2.84073 5.83333C2.55536 5.72998 2.26999 5.6783 1.98462 5.6783C1.45279 5.6783 0.992306 5.87209 0.603165 6.25969C0.201052 6.63437 -3.62392e-06 7.09949 -3.52678e-06 7.65504C-3.41609e-06 8.28812 0.252938 8.83721 0.758822 9.30233C1.25173 9.76744 1.86139 10 2.58779 10C3.4439 10 4.21569 9.63178 4.90318 8.89535C5.59066 8.15892 5.9344 7.2416 5.9344 6.14341C5.9344 4.78682 5.49986 3.55297 4.63078 2.44186C3.7617 1.31783 2.52941 0.523258 0.933935 0.0581416Z" fill="white" fill-opacity="0.4"/>
                      </svg>
                      </span>
                  </div>
                  <h3>Pancham Prashar</h3>
                  <h5>CEO & Co-Founder, CueForGood.com</h5>
                  <div className="btn-grp">
                  <Link to="/contact/" className="btn-white btn-trans">Book a discovery call</Link>

                    <a className="btn-white " href="https://www.linkedin.com/in/pancham/?originalSubdomain=in " target="_blank"
                       rel="noreferrer">
                      <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 1.64748C0 0.738125 0.761304 0 1.69977 0H21.2993C22.2381 0 22.999 0.738125 22.999 1.64748V21.3518C22.999 22.2614 22.2381 22.999 21.2993 22.999H1.69977C0.761393 22.999 0 22.2615 0 21.3521V1.64722V1.64748Z" fill="#0A66C2"/>
                      <path d="M6.98922 19.2469V8.89269H3.54763V19.2469H6.98958H6.98922ZM5.26914 7.47924C6.46905 7.47924 7.21606 6.68416 7.21606 5.69053C7.1936 4.67426 6.46905 3.90137 5.29196 3.90137C4.11407 3.90137 3.34477 4.67426 3.34477 5.69044C3.34477 6.68407 4.09152 7.47915 5.24659 7.47915H5.26887L5.26914 7.47924ZM8.89419 19.2469H12.3355V13.4653C12.3355 13.1562 12.358 12.8464 12.4489 12.6256C12.6976 12.0071 13.2638 11.3668 14.2148 11.3668C15.4598 11.3668 15.9581 12.3162 15.9581 13.7083V19.2469H19.3993V13.3101C19.3993 10.1299 17.7017 8.64995 15.4375 8.64995C13.5811 8.64995 12.7658 9.68751 12.3129 10.3942H12.3358V8.89305H8.89436C8.93928 9.8644 8.8941 19.2473 8.8941 19.2473L8.89419 19.2469Z" fill="white"/>
                      </svg>
                      Let's Connect
                    </a>
                  </div>
                </div>
              </div>
              
            </div>
          </section>

          <section className="bcorp-mid">
            <div className="container">
              <p>We have some exciting news to share! CueForGood is attending the <a href="https://usca.bcorporation.net/champions-retreat-2024/" target="_blank">Champions Retreat 2024</a> in Vancouver, Canada - the much-awaited event for the B Corp movement. </p>
              <p>Champions Retreat 2024 presents a unique opportunity for us to connect with like-minded individuals who aim to improve and be persistent in their purpose-driven objectives.</p>
              <p>As an agency driven by the ethos of service and creating a positive impact on the world, we consciously work with brands, companies, and businesses that do more than just make mere profits.  </p>
            
            </div>
          </section>
          <section className="bcorp-bottom">
            <div className="container">
              <div className="bcorp-bottom-block">
                <h2>What Excites Us About the Champions Retreat 2024</h2>
                <p>CueForGood has served businesses for 18+ years with its client-centric services in eCommerce development, digital marketing, strategic eCommerce planning, and creative design. We are proud Shopify partners and experts in Magento 2 and Wordpress.</p>
               <p>While we strive to achieve B Corp certification in the future, our goal is to be the wind in the sail for businesses with a socially responsible approach to everything they do. See our <a href="/cueblocks-for-good">commitment and journey so far.</a></p>
                <p>B Corps’ standards align very strongly with CueForGood’ vision for a better future. At the Champions Retreat 2024, we look forward to connecting with businesses and leaders with this similar vision of making a positive impact on the word. </p>
                <p>While the entire schedule is packed with intriguing sessions, here are some sessions that we have registered in:</p>
                <ul>
                  <li>Changing the System from Within</li>
                  <li>Different Approaches to Circularity: Collaboration, Localization & Scaling by </li>
                  <li>Designing for Accessibility: Inclusive Practices for Your Digital Presence</li>
                  <li>The Regenerative Era: Transforming Business Models Beyond Sustainability</li>
                  <li>Revolutionizing Rest: Achieving Equity in the Radical Act of Self-Care</li>
                  
                </ul>
                <p>With these sessions, we look forward to deepening our understanding of systemic change and sustainable and inclusive practices in business to better understand businesses that are leading the change. </p>
                <p>We truly believe that when skills and purpose unite, it leads to amazing outcomes. We are committed to empowering B Corps and socially responsible organizations to amplify their impact. </p>
                <p>If you are attending the event and are looking for an eCommerce Agency who can understand your vision and walk the path with you, we’d love to connect with you! </p>
              </div>

          

            </div>
          </section>

      

       </article>
       <Clutch />
      </Layout>
    )
  }
}
